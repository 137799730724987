import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Row, Col } from 'reactstrap'
import articleUrl from 'src/utils/articleUrl.js'
import dayjs from 'dayjs'

const formatYear = (s) => s.replace('{YEAR}', dayjs().format('YYYY'))

const fallbackImage =
  'https://www.datocms-assets.com/7756/1600624248-wikijob-large-2.png?w=220,auto=format,compress'

export default props => {
  const article = props.article
  if (article.seeAlso && article.seeAlso.length > 0) {
    return (
      <div className="further-reading content bg-light px-4">
        <h2>Read This Next</h2>
        <p>You might also be interested in these other WikiJob articles:</p>

        <Row>{article.seeAlso.map(seeAlsoItem)}</Row>

        <p>
          Or explore the{' '}
          <Link to={`/content/${article.category.category.slug}`}>
            {article.category.category.title}
          </Link>{' '}
          /{' '}
          <Link
            to={`/content/${article.category.category.slug}/${article.category.slug}`}
          >
            {article.category.title}
          </Link>{' '}
          sections.
        </p>
      </div>
    )
  } else {
    return null
  }
}

function itemImage(image) {
  return image ? (
    <Img fluid={image.fluid} className="article-cover-image" />
  ) : (
    <div
      className="article-cover-image"
      style={{ backgroundImage: `url(${fallbackImage})` }}
    />
  )
}

function seeAlsoItem(article) {
  return (
    <Col key={article.id} lg={3} md={4} sm={6} xs={12} className="col">
      <Link className="article-link" to={articleUrl(article)}>
        {itemImage(article.coverImage)}
        <span className="article-title">{formatYear(article.title)}</span>
      </Link>
    </Col>
  )
}
