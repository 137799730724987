import React from 'react';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share';

export default ({ location }) => {
  const href = `https://www.wikijob.co.uk${location.pathname}`;
  return (
    <>
      <div
        className="sharingButtonVertical text-center border rounded"
        style={{
          width: 60,
          position: 'fixed',
          top: '440px',
          left: '100px',
          borderRadius: 7,
          overflow: 'hidden',
          zIndex: 2,
          background: 'white',
        }}
      >
        <FacebookShareButton url={href}>
          <FacebookIcon size={58} round={false} />
        </FacebookShareButton>
        <TwitterShareButton url={href} via={'wikijob'}>
          <TwitterIcon size={58} round={false} />
        </TwitterShareButton>
        <LinkedinShareButton url={href}>
          <LinkedinIcon size={58} round={false} />
        </LinkedinShareButton>
        <EmailShareButton
          url={href}
          subject={'Check out this article on WikiJob'}
        >
          <EmailIcon size={58} round={false} />
        </EmailShareButton>{' '}
      </div>
      <div className="sharingButtonHorizontal text-center">
        <FacebookShareButton url={href}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>{' '}
        <TwitterShareButton url={href} via={'wikijob'}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>{' '}
        <LinkedinShareButton url={href}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>{' '}
        <EmailShareButton
          url={href}
          subject={'Check out this article on WikiJob'}
        >
          <EmailIcon size={32} round={true} />
        </EmailShareButton>{' '}
      </div>
    </>
  );
};
