import React from 'react'
import decorateURLWithTID from '../../utils/decorateUrlTid'

const button = (text, url, color, block, id) => (
  <div className="col-md pt-3">
    <a
      id={id}
      className={`btn btn-primary btn-lg hero-button ${
        block ? 'btn-block' : null
      }`}
      role="button"
      href={url}
      rel="nofollow sponsored"
      target="_blank"
      style={{
        background: color,
        color: 'white',
        fontFamily: 'sans',
        border: 'none',
        textTransform: 'uppercase',
        fontWeight: 500,
      }}
    >
      {text}
    </a>
  </div>
)

const HeroBox = ({ location, article }) => (
  <div className="jumbotron">
    <div style={{ verticalAlign: 'middle' }}>
      <div className="d-flex text-center justify-content-center" style={{}}>
        <h2
          style={{
            fontFamily: 'PT Sans',
            background: 'white',
            fontSize: '2em',
            textTransform: 'uppercase',
            opacity: 0.8,
            color: '#222',
            padding: '5px 10px 5px 10px',
          }}
        >
          {article.heroText}
        </h2>
      </div>
      <div className="d-flex justify-content-center pt-4">
        <div className="container">
          <div className="row text-center">
            {article.heroButtonText &&
              article.heroButtonUrl &&
              button(
                article.heroButtonText,
                decorateURLWithTID(
                  article.heroButtonUrl,
                  location.pathname,
                  'hero',
                ),
                article.heroButtonText2 ? null : '#ff9100',
                article.heroButtonText2,
                'heroButton1',
              )}

            {article.heroButtonText2 &&
              article.heroButtonUrl2 &&
              button(
                article.heroButtonText2,
                decorateURLWithTID(
                  article.heroButtonUrl2,
                  location.pathname,
                  'hero',
                ),
                '#ff9100',
                article.heroButtonText2,
                'heroButton2',
              )}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default HeroBox
