import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import Img from 'gatsby-image'
import { useDispatch } from 'react-redux'
import { window, exists } from 'browser-monads'
import { Row, Col } from 'reactstrap'
import dayjs from 'dayjs'
import { ArrowDownCircle } from 'react-feather'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
// import articleUrl from 'src/utils/articleUrl';
// import { ArrowUpCircle } from 'react-feather'
import exitIntent from 'exit-intent'
import StickyArticleHeader from '../components/StickyArticleHeader'
import SidebarLinksForInterviewAdvice from '../components/SidebarLinksForInterviewAdvise'
import HeroBox from '../components/HeroBox/HeroBox'
import ExitIntentPopup from '../components/ExitIntentPopup'
import BuyBox from '../components/BuyBox'
import { setHelloBarContent } from '../store/features/aptitudeTest/aptitudeTestSlice'
import TradeBox from '../components/TradeBox'
import PageHelmet from '../components/PageHelmet'
// import InThisSection from 'src/components/InThisSection'
import styles from './content.module.scss'
import '../nodes/cheerio/cheerio.scss'
import Breadcrumb from '../components/Breadcrumb'
import SocialSharingButtons from '../components/SocialSharingButtons'
import SeeAlso from '../components/SeeAlso'

const formatYear = s => s.replace(/{YEAR}/g, dayjs().format('YYYY'))

const editArticle = articleId => {
  window.open(
    `https://dato.wikijob.co.uk/editor/item_types/50867/items/${articleId}/edit`,
    '_blank'
  )
}

export default function ArticleTemplate({
  data,
  location,
  pageContext: { finalHtml, tocArray },
}) {
  const dispatch = useDispatch()
  const [stickyHeaderShown, setStickyHeaderShown] = useState(false)
  const [eventListeners, setEventListeners] = useState(false)
  const [exitIntentPopupShown, setExitIntentPopupShown] = useState(false)
  const [pageIsScrolled, setPageIsScrolled] = useState(false)

  // const relatedContent = data.datocms.relatedContent
  const article = data.datoCmsArticle
  window.dataLayer &&
    window.dataLayer.push({ article, disableAds: article.disableAds })
  const updatedAt = dayjs(article._updatedAt).format('D MMMM YYYY')
  const canonicalUrl = `https://www.wikijob.co.uk${location.pathname}`

  const ogImage = (image) => {
    if (image) {
      return image.ogImage.src
    }
    return null
  }

  if (exists(window)) {
    useScrollPosition(({ prevPos, currPos }) => {
      if (!stickyHeaderShown && currPos.y < -650) {
        setStickyHeaderShown(true)
      }
      if (stickyHeaderShown && currPos.y > -650) {
        setStickyHeaderShown(false)
      }
      if (
        prevPos.y !== 0 &&
        prevPos.y !== currPos.y &&
        location.pathname.includes('aptitude-tests')
      ) {
        if (!pageIsScrolled) {
          exitIntent({
            threshold: 18,
            maxDisplays: 1,
            eventThrottle: 500,
            onExitIntent: () => {
              setExitIntentPopupShown(true)
            },
          })
          setPageIsScrolled(true)
        }
      }
    })

    useEffect(() => {
      if (
        article.heroButtonUrl &&
        !article.heroButtonUrl.includes('aptitude-tests-home')
      ) {
        dispatch(
          setHelloBarContent({
            url:
              article.helloBarUrl ||
              article.heroButtonUrl ||
              'https://tests.wikijob.co.uk/hello-bar?tid2=hellobar',
            buttonText:
              article.helloBarButtonText || `Take a ${article.title} test!`,
            text:
              article.helloBarText ||
              `Don't lose out on that job. Practice aptitude tests today.`,
          }),
        )
      }
      if (!eventListeners) {
        const handlePressedKey = (event) => {
          if (event.ctrlKey && event.key === 'b') {
            editArticle(article.originalId)
          }
        }
        window.addEventListener('keydown', handlePressedKey)
        setEventListeners(true)
      }
    }, [
      dispatch,
      eventListeners,
      article.originalId,
      article.heroButtonUrl,
      article.helloBarUrl,
      article.helloBarButtonText,
      article.title,
      article.helloBarText,
    ])
  }

  const showBuyBox = () => {
    const buyBoxURLs = ['aptitude-test', 'procter-gamble', 'credit-suisse']
    return !!buyBoxURLs.find((URL) => location.pathname.match(URL))
  }

  window.dataLayer && window.dataLayer.push({ event: 'componentDidMount' })

  let heroBox = null
  if (article.heroText) {
    heroBox = <HeroBox article={article} location={location} />
  } else if (article.category.category.slug === 'aptitude-tests') {
    heroBox = (
      <HeroBox
        article={{
          heroText: 'Free Practice Aptitude Test',
          heroButtonText: 'Take Test',
          heroButtonUrl: 'https://tests.wikijob.co.uk/aptitude-tests-home',
        }}
        location={location}
      />
    )
  }

  return (
    <>
      <div
        className={`
          ${styles.stickyArticleHeaderWrapper}
          ${stickyHeaderShown && styles.stickyArticleHeaderWrapperIsShown}
        `}
      >
        <StickyArticleHeader article={article} location={location} />
      </div>
      <PageHelmet
        {...{
          id: 507826,
          title: formatYear(article.metaTitle || article.title),
          description:
            article.metaDescription && formatYear(article.metaDescription),
          image: ogImage(article.coverImage),
          url: canonicalUrl,
          lang: article.language,
        }}
      />
      {article.coverImage && (
        <div className="pb-5">
          <Img
            fluid={article.coverImage.fluid}
            alt={article.title}
            className={styles.coverImage}
          />
        </div>
      )}
      <Row>
        <Col lg={8} className="content article-content">
          <h1 id="article-title">{formatYear(article.title)}</h1>
          <Breadcrumb article={article} />
          <p className="lastUpdated my-3 p-0">
            Updated{' '}
            <span
              {...{
                itemProp: 'dateModified',
                content: article._updatedAt,
              }}
            >
              {updatedAt}
            </span>
          </p>
          {heroBox}
          {tocArray.length > 0 && (
            <div id="articleContents" className="toc">
              <h3>
                <strong>In this article</strong>{' '}
                <small className="text-secondary">Skip to section</small>
              </h3>
              <div className="js-toc">
                <ul id="serverToc">{tocArray.map(tocRow)}</ul>
              </div>
            </div>
          )}
          <SocialSharingButtons location={location} />
          <div
            {...{
              className: classNames(styles.content, 'mediavineAdsInContent'),
              dangerouslySetInnerHTML: { __html: finalHtml },
            }}
          />
          {heroBox}
        </Col>
        <Col lg={4}>
          {location.pathname.match('/content/trading') &&
            article.disableAds && <TradeBox />}

          {article.author && (
            <div className={classNames(styles.flexCenter, 'author pb-5')}>
              <span className="pr-2">
                By {article.author.name}
                {'  '}
              </span>
              {article.author.picture.fixed && (
                <Img
                  className="rounded-circle"
                  fixed={article.author.picture.fixed}
                  alt={article.author.name}
                />
              )}
            </div>
          )}
          {/* Related Content articles, now it's in the header */}

          {/* {relatedContent.length > 1 && (
          <Row>
            <Col>
              <InThisSection
                relatedContent={relatedContent}
                excludeId={article.originalId}
                title={article.category.title}
              />
            </Col>
          </Row>
        )} */}
          <Row>
            <Col>
              <div
                {...{
                  className: 'mediavineAdsTop', // TODO: fixed class for Mediavine, don't change
                  id: 'mediavineAdsTop', // TODO: fixed id for Mediavine, don't change
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col className="pt-5 pb-5">
              <div style={{ marginTop: '98px' }}>
                {location.pathname.match('interview-advice') && (
                  <SidebarLinksForInterviewAdvice />
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                {...{
                  className: 'mediavineAdsBottom', // TODO: fixed class for Mediavine, don't change
                  id: 'mediavineAdsBottom', // TODO: fixed id for Mediavine, don't change
                }}
              />
            </Col>
          </Row>
          {showBuyBox() && (
            <BuyBox
              testUrl={article.heroButtonUrl}
              marginTop="0"
              location={location}
            />
          )}
        </Col>
      </Row>
      {article.seeAlso && <SeeAlso article={article} />}

      {/* NOTE: Scroll up feature (needs to be tested well) */}
      {/* <div className="scroll-to-top-container">
        <a className="scroll-to-top" href="#articleContents">
          <ArrowUpCircle />
        </a>
      </div> */}

      {exitIntentPopupShown && pageIsScrolled && (
        <ExitIntentPopup
          pathname={location.pathname}
          closePopup={() => setExitIntentPopupShown(false)}
          testUrl={article.heroButtonUrl || article.heroButtonUrl2}
        />
      )}
    </>
  )
}

function tocRow({ id, text, tagName }) {
  switch (tagName) {
    case 'h2':
      return (
        <li key={id}>
          <a className="tocLink" href={`#${id}`}>
            <ArrowDownCircle className="d-none d-md-block"  />
            <span className="link-text">{text}</span>
          </a>
        </li>
      )
    case 'h3':
      return (
        <li key={id} className="h3toc">
          <a className="tocLink" href={`#${id}`} >
            <ArrowDownCircle className="d-none d-md-block" />
            <span className="link-text">{text}</span>
          </a>
        </li>
      )
    default:
      break
  }
}

export const query = graphql`
  query($id: String!, $categoryId: String!) {
    relatedContent: allDatoCmsArticle(
      limit: 100
      filter: { category: { id: { eq: $categoryId } } }
      sort: { fields: title, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          id
          shortUrl
          language
          category {
            id
            category {
              title
              id
              slug
            }
            title
            slug
          }
        }
      }
    }
    datoCmsArticle(id: { eq: $id }) {
      originalId
      title
      slug
      metaTitle
      metaDescription
      meta {
        updatedAt
      }
      language
      disableAds
      author {
        name
        picture {
          fixed(
            width: 50
            height: 50
            imgixParams: {
              auto: "compress, format, enhance"
              fit: "facearea"
              w: "50"
              h: "50"
              facepad: 3
            }
          ) {
            ...GatsbyDatoCmsFixed
          }
        }
      }
      shortUrl
      helloBarButtonText
      helloBarText
      helloBarUrl
      heroText
      heroButtonText
      heroButtonUrl
      heroButtonText2
      heroButtonUrl2
      coverImage {
        fluid(
          imgixParams: {
            w: "1000"
            h: "400"
            fit: "crop"
            auto: "compress,format,enhance"
            q: 70
          }
        ) {
          ...GatsbyDatoCmsFluid
        }
        ogImage: fixed(width: 1600, imgixParams: { auto: "enhance" }) {
          ...GatsbyDatoCmsFixed
        }
      }
      seeAlso {
        id
        title
        slug
        shortUrl
        coverImage {
          fluid(
            imgixParams: {
              q: 70
              auto: "compress, format, enhance"
              fit: "crop"
              w: "220"
              h: "150"
            }
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
        category {
          id
          title
          slug
          category {
            id
            title
            slug
          }
        }
      }
      category {
        id
        title
        slug
        category {
          id
          title
          slug
        }
      }
    }
  }
`
