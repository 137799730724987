import React from 'react'
import { Link } from 'gatsby'

export default (props) => {
  const article = props.article

  return (
    <nav aria-label='breadcrumb'>
      <ol
        className='breadcrumb m-0'
        id='breadcrumblist'
        itemScope
        itemType='http://schema.org/BreadcrumbList'>
        <li
          itemProp='itemListElement'
          itemScope
          itemType='http://schema.org/ListItem'
          className='d-none d-md-block breadcrumb-item breadcrumb-item--home'
          aria-current='page'>
          <Link itemProp='item' to='/'>
            <span itemProp='name'>Home</span>
            <meta itemProp='position' content='1' />
          </Link>
        </li>
        <li
          itemProp='itemListElement'
          itemScope
          itemType='http://schema.org/ListItem'
          className='breadcrumb-item'
          aria-current='page'>
          <Link
            itemProp='item'
            to={`/content/${article.category.category.slug}`}>
            <span itemProp='name'>{article.category.category.title}</span>
            <meta itemProp='position' content='2' />
          </Link>
        </li>
        <li
          itemProp='itemListElement'
          itemScope
          itemType='http://schema.org/ListItem'
          className='breadcrumb-item active'
          aria-current='page'>
          <Link
            itemProp='item'
            to={`/content/${article.category.category.slug}/${article.category.slug}`}>
            <span itemProp='name'>{article.category.title}</span>
            <meta itemProp='position' content='3' />
          </Link>
        </li>
      </ol>
    </nav>
  )
}
