import React from 'react'
import styles from './styles.module.scss'
import dayjs from 'dayjs'
import decorateURLWithTID from '../../utils/decorateUrlTid'

const formatYear = (s) => s.replace('{YEAR}', dayjs().format('YYYY'))

const StickyArticleHeader = ({ article, location }) => {
  return (
    <div className={styles.stickyArticleHeader}>
      <div className={`container ${styles.stickyArticleHeaderContainer}`}>
        <h2>
          {formatYear(article.heroText ? article.heroText : article.title)}
        </h2>

        {article.heroButtonText && article.heroButtonText && (
          <a
            href={decorateURLWithTID(article.heroButtonUrl,
              location.pathname, 'stickyheader')}
            className={`stickyHeaderButton btn btn-orange ${styles.stickyArticleHeaderButton}`}
            role="button"
            aria-pressed="true"
            rel="sponsored nofollow noreferrer"
            target='_blank'
          >
            {article.heroButtonText}
          </a>
        )}
      </div>
    </div>
  )
}

export default StickyArticleHeader
