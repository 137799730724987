import React from 'react'
import './ExitIntentPopup.css'
import decorateURLWithTID from '../../utils/decorateUrlTid'

const ExitIntentPopup = ({ closePopup, testUrl, pathname }) => (
  <div className="popup-wrapper" onClick={closePopup}>
    <div className="popup exit-intent-popup">
      <div className="popup-content">
        <h2>Don't lose out on that job!</h2>
        <p className="large-text">
          Practice aptitude tests today with JobTestPrep.
        </p>
        <a
          className="btn btn-orange btn-lg trackingLink exitPopupButton"
          href={
            testUrl && pathname
              ? decorateURLWithTID(testUrl, pathname, 'exitintent')
              : 'https://tests.wikijob.co.uk/hello-bar'
          }
          role="button"
          aria-pressed="true"
          rel="sponsored nofollow noreferrer"
          target="_blank"
        >
          Get more practice
        </a>
      </div>

      <div className="popup-close-button" onClick={closePopup} role="button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.3607 19.78L12.0007 13.41L5.6407 19.78L4.2207 18.36L10.5907 12L4.2207 5.63997L5.6407 4.21997L12.0007 10.59L18.3607 4.22997L19.7707 5.63997L13.4107 12L19.7707 18.36L18.3607 19.78Z" />
        </svg>
      </div>
    </div>
  </div>
)
export default ExitIntentPopup
