import React from 'react'
import { window, exists } from 'browser-monads'
import classNames from 'classnames'
import styles from './TradeBox.module.scss'
import decorateURLWithTID from '../../utils/decorateUrlTid'

const TradeBox = ({  positionTop, zIndex, marginTop }) => {
  const mainFeatures = [
    'Easy Trading Platform',
    'Copy Experienced Traders',
    'Trade from Your Pocket'
  ]

  const features = [
    'Best Mobile App',
    'Free Trading Courses',
    'Low Fees',
    'Fast Execution',
    '24/7 Customer Support'
  ]
  const getUrl = () => {
      return 'https://l.wikijob.co.uk/etoro'
  }
  const onClickOnBuyBox = () => {
    if (exists(window)) {
        window.open(
          getUrl(),
          '_blank'
        )
    }
  }

  return (
    <div
      className="w-100"
      style={{
        position: 'sticky',
        top: positionTop || '33px',
        zIndex: zIndex || '1001',
        minHeight: '500px',
      }}
    >
      <div
        className={classNames('trackingLink allbuyBox', styles.buyBoxPopup)}
        style={{ marginTop: marginTop || '0' }}
        onClick={onClickOnBuyBox}
      >
        <div className={styles.buyBoxPopupHeader}>
          <h2>Want to Trade Online?</h2>

          <div className={styles.buyBoxPopupMainFeatures}>
            {mainFeatures.map((feature, i) => (
              <div className={styles.buyBoxPopupMainFeature} key={i}>
                {i === 0 ? (
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24.0156"
                      cy="24.491"
                      r="24"
                      fill="#FFEAB4"
                      fillOpacity="0.6"
                    />
                    <path
                      d="M20.8164 24.1C20.8164 23.3574 21.1114 22.6453 21.6365 22.1201C22.1616 21.595 22.8738 21.3 23.6164 21.3C24.359 21.3 25.0712 21.595 25.5963 22.1201C26.1214 22.6453 26.4164 23.3574 26.4164 24.1C26.4164 24.8427 26.1214 25.5548 25.5963 26.0799C25.0712 26.6051 24.359 26.9001 23.6164 26.9001C22.8738 26.9001 22.1616 26.6051 21.6365 26.0799C21.1114 25.5548 20.8164 24.8427 20.8164 24.1ZM23.6164 22.7C23.2451 22.7 22.889 22.8475 22.6265 23.1101C22.3639 23.3727 22.2164 23.7287 22.2164 24.1C22.2164 24.4714 22.3639 24.8274 22.6265 25.09C22.889 25.3526 23.2451 25.5 23.6164 25.5C23.9877 25.5 24.3438 25.3526 24.6064 25.09C24.8689 24.8274 25.0164 24.4714 25.0164 24.1C25.0164 23.7287 24.8689 23.3727 24.6064 23.1101C24.3438 22.8475 23.9877 22.7 23.6164 22.7Z"
                      fill="#E3650C"
                    />
                    <path
                      d="M15.9164 17.1001C15.3595 17.1001 14.8253 17.3213 14.4315 17.7152C14.0377 18.109 13.8164 18.6431 13.8164 19.2001V29.0001C13.8164 29.5571 14.0377 30.0912 14.4315 30.485C14.8253 30.8788 15.3595 31.1001 15.9164 31.1001H31.3164C31.8734 31.1001 32.4075 30.8788 32.8013 30.485C33.1952 30.0912 33.4164 29.5571 33.4164 29.0001V19.2001C33.4164 18.6431 33.1952 18.109 32.8013 17.7152C32.4075 17.3213 31.8734 17.1001 31.3164 17.1001H15.9164ZM15.2164 19.2001C15.2164 19.0144 15.2902 18.8364 15.4214 18.7051C15.5527 18.5738 15.7308 18.5001 15.9164 18.5001H18.0164V19.9001C18.0164 20.2714 17.8689 20.6275 17.6064 20.89C17.3438 21.1526 16.9877 21.3001 16.6164 21.3001H15.2164V19.2001ZM15.2164 22.7001H16.6164C17.359 22.7001 18.0712 22.4051 18.5963 21.88C19.1214 21.3549 19.4164 20.6427 19.4164 19.9001V18.5001H27.8164V19.9001C27.8164 20.6427 28.1114 21.3549 28.6365 21.88C29.1616 22.4051 29.8738 22.7001 30.6164 22.7001H32.0164V25.5001H30.6164C29.8738 25.5001 29.1616 25.7951 28.6365 26.3202C28.1114 26.8453 27.8164 27.5575 27.8164 28.3001V29.7001H19.4164V28.3001C19.4164 27.5575 19.1214 26.8453 18.5963 26.3202C18.0712 25.7951 17.359 25.5001 16.6164 25.5001H15.2164V22.7001ZM29.2164 18.5001H31.3164C31.5021 18.5001 31.6801 18.5738 31.8114 18.7051C31.9427 18.8364 32.0164 19.0144 32.0164 19.2001V21.3001H30.6164C30.2451 21.3001 29.889 21.1526 29.6265 20.89C29.3639 20.6275 29.2164 20.2714 29.2164 19.9001V18.5001ZM32.0164 26.9001V29.0001C32.0164 29.1857 31.9427 29.3638 31.8114 29.4951C31.6801 29.6263 31.5021 29.7001 31.3164 29.7001H29.2164V28.3001C29.2164 27.9288 29.3639 27.5727 29.6265 27.3101C29.889 27.0476 30.2451 26.9001 30.6164 26.9001H32.0164ZM18.0164 29.7001H15.9164C15.7308 29.7001 15.5527 29.6263 15.4214 29.4951C15.2902 29.3638 15.2164 29.1857 15.2164 29.0001V26.9001H16.6164C16.9877 26.9001 17.3438 27.0476 17.6064 27.3101C17.8689 27.5727 18.0164 27.9288 18.0164 28.3001V29.7001Z"
                      fill="#E3650C"
                    />
                    <path
                      d="M34.8173 29C34.8173 29.9283 34.4486 30.8185 33.7922 31.4749C33.1358 32.1313 32.2456 32.5 31.3173 32.5H16.7363C16.8811 32.9097 17.1494 33.2643 17.5042 33.515C17.859 33.7657 18.2829 33.9003 18.7173 33.9H31.3173C32.6169 33.9 33.8632 33.3838 34.7822 32.4649C35.7011 31.5459 36.2173 30.2996 36.2173 29V22C36.2176 21.5656 36.083 21.1418 35.8323 20.787C35.5815 20.4322 35.2269 20.1639 34.8173 20.019V29Z"
                      fill="#E3650C"
                    />
                  </svg>
                ) : i === 1 ? (
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="24.0156"
                      cy="24.491"
                      r="24"
                      fill="#FFEAB4"
                      fillOpacity="0.6"
                    />
                    <path
                      d="M30.8906 34.125H29.5156V32.0625C29.5145 31.1512 29.152 30.2774 28.5076 29.633C27.8632 28.9886 26.9895 28.6261 26.0781 28.625H21.9531C21.0418 28.6261 20.1681 28.9886 19.5237 29.633C18.8792 30.2774 18.5167 31.1512 18.5156 32.0625V34.125H17.1406V32.0625C17.1421 30.7866 17.6496 29.5634 18.5518 28.6612C19.454 27.759 20.6772 27.2515 21.9531 27.25H26.0781C27.354 27.2515 28.5773 27.759 29.4795 28.6612C30.3817 29.5634 30.8892 30.7866 30.8906 32.0625V34.125Z"
                      fill="#E3650C"
                    />
                    <path
                      d="M16.4531 17.625C16.2708 17.625 16.0959 17.6974 15.967 17.8264C15.8381 17.9553 15.7656 18.1302 15.7656 18.3125V24.5H17.1406V18.3125C17.1406 18.1302 17.0682 17.9553 16.9393 17.8264C16.8103 17.6974 16.6355 17.625 16.4531 17.625Z"
                      fill="#E3650C"
                    />
                    <path
                      d="M15.7656 14.875V16.25H19.2031V21.0625C19.2031 22.3389 19.7102 23.5629 20.6127 24.4655C21.5152 25.368 22.7393 25.875 24.0156 25.875C25.292 25.875 26.5161 25.368 27.4186 24.4655C28.3211 23.5629 28.8281 22.3389 28.8281 21.0625V16.25H32.2656V14.875H15.7656ZM20.5781 16.25H27.4531V18.3125H20.5781V16.25ZM24.0156 24.5C23.1039 24.5 22.2296 24.1378 21.5849 23.4932C20.9403 22.8485 20.5781 21.9742 20.5781 21.0625V19.6875H27.4531V21.0625C27.4531 21.9742 27.091 22.8485 26.4463 23.4932C25.8016 24.1378 24.9273 24.5 24.0156 24.5Z"
                      fill="#E3650C"
                    />
                  </svg>
                ) : (
                  <svg
                    width="49"
                    height="49"
                    viewBox="0 0 49 49"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="sandro"
                      cx="24.0156"
                      cy="24.491"
                      r="24"
                      fill="#FFEAB4"
                      fillOpacity="0.6"
                    />
                    <path
                      d="M33.3657 32.5004H13.8874V27.8534C13.3693 27.6053 12.8985 27.2686 12.4961 26.8586V32.5004C12.4961 32.8694 12.6427 33.2233 12.9036 33.4842C13.1645 33.7451 13.5184 33.8917 13.8874 33.8917H33.3657C33.7347 33.8917 34.0885 33.7451 34.3495 33.4842C34.6104 33.2233 34.757 32.8694 34.757 32.5004V26.8586C34.3575 27.2702 33.8859 27.6051 33.3657 27.8465V32.5004Z"
                      fill="#E3650C"
                    />
                    <path
                      d="M28.4959 28.6045C28.6804 28.6045 28.8574 28.5312 28.9878 28.4008C29.1183 28.2703 29.1916 28.0934 29.1916 27.9089V24.1106H27.8003V25.5436H20.8438V26.9349H27.8003V27.9089C27.8003 28.0934 27.8736 28.2703 28.004 28.4008C28.1345 28.5312 28.3114 28.6045 28.4959 28.6045Z"
                      fill="#E3650C"
                    />
                    <path
                      d="M34.0613 17.1958H27.8004V16.0689C27.7931 15.6236 27.6097 15.1993 27.2902 14.889C26.9708 14.5787 26.5414 14.4076 26.0961 14.4132H21.157C20.7117 14.4076 20.2823 14.5787 19.9628 14.889C19.6434 15.1993 19.4599 15.6236 19.4526 16.0689V17.1958H13.1917C13.0072 17.1958 12.8303 17.2691 12.6998 17.3996C12.5694 17.53 12.4961 17.707 12.4961 17.8915V23.4567C12.4961 24.3792 12.8626 25.2639 13.5149 25.9162C14.1672 26.5685 15.0519 26.935 15.9744 26.935H18.0613V27.9089C18.0613 28.0934 18.1346 28.2703 18.2651 28.4008C18.3955 28.5312 18.5725 28.6045 18.757 28.6045C18.9415 28.6045 19.1184 28.5312 19.2489 28.4008C19.3793 28.2703 19.4526 28.0934 19.4526 27.9089V24.1106H18.0613V25.5437H15.9744C15.4209 25.5437 14.89 25.3238 14.4987 24.9324C14.1073 24.541 13.8874 24.0102 13.8874 23.4567V18.5871H33.3657V23.4567C33.3657 24.0102 33.1458 24.541 32.7544 24.9324C32.363 25.3238 31.8322 25.5437 31.2787 25.5437H30.5831V26.935H31.2787C32.2012 26.935 33.0859 26.5685 33.7382 25.9162C34.3905 25.2639 34.757 24.3792 34.757 23.4567V17.8915C34.757 17.707 34.6837 17.53 34.5532 17.3996C34.4228 17.2691 34.2458 17.1958 34.0613 17.1958ZM26.4091 17.1958H20.8439V16.1037C20.8475 16.0231 20.8821 15.947 20.9404 15.8912C20.9987 15.8355 21.0763 15.8044 21.157 15.8045H26.1031C26.1423 15.8036 26.1814 15.8104 26.2181 15.8246C26.2547 15.8388 26.2882 15.8601 26.3166 15.8872C26.3451 15.9143 26.3679 15.9468 26.3837 15.9827C26.3996 16.0187 26.4082 16.0574 26.4091 16.0967V17.1958Z"
                      fill="#E3650C"
                    />
                  </svg>
                )}
                {feature}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.buyBoxPopupBody}>
          <div className={styles.buyBoxPopupListGroup}>
            <div className={styles.buyBoxPopupListGroupHeading}>
                Trade with eToro
            </div>
            {features.map((feature, i) => (
              <span key={i} className={styles.buyBoxPopupListGroupItem}>
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: '5px' }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.956 7.96397C17.1659 7.75587 17.4493 7.63876 17.7449 7.63794C18.0405 7.63713 18.3245 7.75268 18.5356 7.95961C18.7466 8.16654 18.8678 8.44823 18.8728 8.74377C18.8778 9.03931 18.7664 9.32496 18.5625 9.53897L12.5745 17.024C12.4716 17.1348 12.3473 17.2238 12.2093 17.2855C12.0712 17.3473 11.9221 17.3805 11.7708 17.3833C11.6196 17.3861 11.4694 17.3584 11.3291 17.3018C11.1888 17.2452 11.0614 17.1609 10.9545 17.054L6.98697 13.085C6.87644 12.982 6.78779 12.8578 6.7263 12.7198C6.66481 12.5818 6.63175 12.4328 6.62908 12.2817C6.62642 12.1307 6.6542 11.9807 6.71078 11.8406C6.76737 11.7005 6.85158 11.5732 6.95841 11.4664C7.06524 11.3596 7.19249 11.2754 7.33257 11.2188C7.47265 11.1622 7.6227 11.1344 7.77375 11.1371C7.92481 11.1397 8.07378 11.1728 8.21178 11.2343C8.34978 11.2958 8.47398 11.3844 8.57697 11.495L11.718 14.6345L16.9275 7.99697C16.9368 7.98535 16.9468 7.97432 16.9575 7.96397H16.956Z"
                    fill="#F0873D"
                  />
                </svg>
                {feature}
              </span>
            ))}
          </div>
          <a
            href={
             getUrl()
            }
            role="button"
            aria-pressed="true"
            rel="sponsored nofollow noreferrer"
            target="_blank"
            className="btn btn-orange btn-lg btn-block trackingLink buyBox"
            style={{
              borderRadius: '10px',
              padding: '0.5rem 0.8rem',
              fontSize: '18px',
              textTransform: 'uppercase',
            }}
          >
            {/* Variant with test name in a line below */}
            {/* Get {testName} Now */}
            Start Now
          </a>

          <div className={styles.buyBoxPopupLabel}>with eToro</div>
        </div>
      </div>
    </div>
  )
}

export default TradeBox
